import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environments';
import { Dishes, ResponseDishes } from '../interfaces/dishes.interface';
import { Fridge, ResponseFridge } from '../interfaces/fridge.interface';
import { ResponseListIngredients, ResponseIngredients } from '../interfaces/ingredientApi.interface';
import { OrderRequest, OrderResponse, OrderVerification } from '../interfaces/order.interface';
import { AutocompleteResponse, LocationErrors, LocationResponse } from '../interfaces/location.interface';
import { FridgesNear, FridgesNearResponse } from '../interfaces/api/fridge-response.interface';
import { MealsResponse } from '../interfaces/api/meals-response.interface';
import { PaymentInfoResponse } from '../interfaces/api/reportePedido-response.interface';

@Injectable({
  providedIn: 'root'
})
export class FitMealsService {

  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) { }

  //* Solicitudes al API

  getDishes():Observable<ResponseDishes>{
    const url = `${ this.baseUrl }/Operaciones/GetPlatos`;

    const params = new HttpParams()
      .set('idPlan', 1);

      return this.http.get<ResponseDishes>(url, { params })
      .pipe(
        map((data) => data),
        catchError( () => throwError( () => 'Ha ocurrido un error, intente nuevamente' ))
      );
  }

  getMeals(idGoal: number = 0):Observable<ResponseDishes>{
    const url = `${this.baseUrl}/Operaciones/GetPlatosByObjetivo`;

    const params = new HttpParams()
      .set('idObjetivo', idGoal);

      return this.http.get<MealsResponse>(url, { params })
      .pipe(
        map((data) => data),
        catchError( () => throwError( () => 'Ha ocurrido un error, intente nuevamente' ))
      );
  }

  getFridge():Observable<ResponseFridge>{
    const url = `${ this.baseUrl }/Operaciones/GetNeveras`;

    return this.http.get<ResponseFridge>(url)
    .pipe(
      map((data) => data),
      catchError( () => throwError( () => 'Ha ocurrido un error, intente nuevamente' ))
    );
  }

  getIngredients():Observable<ResponseIngredients>{
    const url = `${ this.baseUrl }/Operaciones/GetIngredientes`;

    return this.http.get<ResponseIngredients>(url)
    .pipe(
      map((data) => data),
      catchError( () => throwError( () => 'Ha ocurrido un error, intente nuevamente' ))
    );
  }

  getDireccion(lat:string,long:string):Observable<any>{
    const url = `${ this.baseUrl }/Operaciones/SearchDirectionByCoorde`;
    const params = new HttpParams()
    .set('lat', lat).set('lon',long)
    return this.http.get<any>(url, {params, responseType: 'json' } )
  }

  getAddress(lat:string,long:string):Observable<any>{
    const url = `${this.baseUrl}/Operaciones/v2/SearchDirectionByCoorde`;
    const params = new HttpParams()
    .set('lat', lat).set('lon',long)
    return this.http.get<any>(url, {params, responseType: 'json' } )
  }

  getAddressDelivery(coordinates: string):Observable<any>{
    const url = `${this.baseUrl}/Operaciones/v2/GetNearbyDelivery`;
    const params = new HttpParams()
    .set('coordenates', coordinates)
    return this.http.get<any>(url, {params, responseType: 'json' } )
  }

  getCoords(address: string): Observable<LocationResponse[]|LocationErrors> {
    const url = `${ this.baseUrl }/Operaciones/SearchDirectionByLocation`;
    const params = new HttpParams().set('location', address)
    return this.http.get<LocationResponse[]|LocationErrors>(url, {params, responseType: 'json' } )
      .pipe(
        catchError(error => {
          return throwError('Ha ocurrido un error, por favor intenta de nuevo');
        })
      );
  }

  autocompleteAddress(address: string): Observable<AutocompleteResponse> {
    const url = `${ this.baseUrl }/Operaciones/v2/SearchDirectionByLocation`;
    const params = new HttpParams().set('location', address)
    return this.http.get<AutocompleteResponse>(url, {params, responseType: 'json' } )
      .pipe(
        catchError(error => {
          return throwError('Ha ocurrido un error, por favor intenta de nuevo');
        })
      );
  }

  autocompleteAllAddress(address: string): Observable<AutocompleteResponse> {
    const url = `${ this.baseUrl }/Operaciones/v2/SearchDirectionByLocationAll`;
    const params = new HttpParams().set('location', address)
    return this.http.get<AutocompleteResponse>(url, {params, responseType: 'json' } )
      .pipe(
        catchError(error => {
          return throwError('Ha ocurrido un error, por favor intenta de nuevo');
        })
      );
  }

  getPaymentInfo(order: OrderVerification):Observable<PaymentInfoResponse>{
    const url = `${this.baseUrl}/Operaciones/PedidoDetalleReporte`;

    return this.http.post<PaymentInfoResponse>(url, order)
    .pipe(
      catchError(error => {
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }


  createOrder(order: OrderRequest): Observable<OrderResponse>{
    const url = `${this.baseUrl}/Operaciones/RealizarPedidoCliente`;

    return this.http.post<OrderResponse>(url, order)
      .pipe(
        catchError(error => {
          return throwError('Ha ocurrido un error, por favor intenta de nuevo');
        })
      );
  }




  getFridgesNear(coordinates: string): Observable<FridgesNearResponse>{
    const url = `${this.baseUrl}/Operaciones/v2/GetNearbyBriges`;
    const params = new HttpParams().set('coordenates', coordinates)
    return this.http.get<FridgesNearResponse>(url, {params, responseType: 'json' })
      .pipe(
        catchError(error => {
          return throwError('Ha ocurrido un error, por favor intenta de nuevo');
        })
      );
  }



}
