<section id="contact" class="contact w-full flex flex-col items-center">
    <div class="container flex flex-col items-center justify-center" data-aos="fade-up">

      <div class="section-header">
        <h2>Contact</h2>
        <p>You can contact us whenever you want, if you have questions, want more information or have a problem when placing your order.</p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 w-full md:w-10/12">

        <div class="col-span-1">

          <div class="info-container d-flex flex-column align-items-center justify-content-center">
            <div class="info-item d-flex">
              <i class="bi bi-geo-alt flex-shrink-0"></i>
              <div>
                <h4>Location:</h4>
                <p>3601 Vineland Rd Suite 1, Orlando, FL 32811</p>
              </div>
            </div><!-- End Info Item -->

            <div class="info-item d-flex">
              <i class="bi bi-envelope flex-shrink-0"></i>
              <div>
                <h4>Email:</h4>
                <a href="mailto:info@mgmeals.com">info&#64;mgmeals.com</a>
              </div>
            </div><!-- End Info Item -->

            <div class="info-item d-flex">
              <i class="bi bi-phone flex-shrink-0"></i>
              <div>
                <h4>Call:</h4>
                <a href="tel:+14072321846">+1 407-232-1846</a>
              </div>
            </div><!-- End Info Item -->

            <div class="info-item d-flex">
              <i class="bi bi-clock flex-shrink-0"></i>
              <div>
                <h4>Open Hours:</h4>
                <p>Every day: 06:00 a.m. - 08:00 p.m.</p>
              </div>
            </div><!-- End Info Item -->
          </div>

        </div>

        <div class="col-span-1 md:col-span-2">
            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div class="col-span-1">
                    <mat-form-field class="w-full" appearance="outline">
                      <mat-label>Your Name</mat-label>
                      <input
                        class="focus:ring-0"
                        matInput
                        type="tel"
                        formControlName="phone"
                      />
                    </mat-form-field>
                </div>
                <div class="col-span-1">
                  <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Your Email</mat-label>
                    <input
                      class="focus:ring-0"
                      matInput
                      type="tel"
                      formControlName="phone"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="mt-3">
                <mat-form-field class="w-full" appearance="outline">
                  <mat-label>Subject</mat-label>
                  <input
                    class="focus:ring-0"
                    matInput
                    type="tel"
                    formControlName="phone"
                  />
                </mat-form-field>
              </div>
              <div class="mt-3">
                <mat-form-field class="w-full" appearance="outline">
                  <mat-label>Message</mat-label>
                  <textarea matInput class="focus:ring-0" formControlName="comments" rows="7"></textarea>
                </mat-form-field>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Send Message</button></div>
            </form>
          </div>
          <!-- End Contact Form -->

      </div>

    </div>
  </section>