<header id="header" class="header flex justify-center items-center w-full drop-shadow-md" [ngClass]="{ 'fixed top-0': isFixed }">

  <div class="flex items-center justify-between w-full md:w-10/12 px-4 lg:px-0">
    <a routerLink="/" class="logo flex items-center cursor-pointer order-2 lg:order-1">
      <img src="../assets/images/logo-menu.png" alt="" class="">
    </a>
    <div class="flex gap-5 items-center order-1 md:order-2 w-3/12 lg:w-auto">
      <nav id="navbar" class="navbar hidden lg:block">
        <ul>
          <li><a (click)="onLinkClick('hero')" class="cursor-pointer">Home</a></li>
          <li><a (click)="onLinkClick('about')" class="cursor-pointer">Why choose us?</a></li>
          <li><a (click)="onLinkClick('plans')" class="cursor-pointer">Plans</a></li>
          <li><a (click)="onLinkClick('membership')" class="cursor-pointer">Membership</a></li>
          <li><a (click)="onLinkClick('contact')" class="cursor-pointer">Contact</a></li>
        </ul>
      </nav><!-- .navbar -->
      <a routerLink="/order" class="get-started-btn scrollto cursor-pointer hidden lg:block">Order now</a>
      <div class="h-full flex items-center gap-0 lg:gap-1">
        <button mat-icon-button color="primary" aria-label="Example icon button with a home icon" (click)="opProfile.toggle($event)" class="btnIcon">
          <mat-icon
            class="text-2xl lg:text-3xl iconHeader"
          >
            account_circle
            <span class="p-1 rounded-full absolute right-2  bottom-2" [ngClass]="{'bg-green-400': authService.isLoged | async, 'bg-red-600': !(authService.isLoged | async)}"></span>
          </mat-icon>
        </button>
        <p-overlayPanel #opProfile class="rounded-xl" cdkScrollable>
          <div class="flex w-60 justify-center" *ngIf="!(authService.isLoged | async); else menuUser">
            <div class="flex flex-col items-center gap-4">
              <span class="flex justify-center items-center w-16 h-16 bg-primary-700 rounded-full">
                <mat-icon class="iconCart text-5xl text-white">person</mat-icon>
              </span>
                <span routerLink="sign-in" class="text-center font-bold">
                  Do you wanna enjoy our delicious meals? <span class="hover:text-gray-600 font-medium cursor-pointer text-primary-700">Sign in</span>
                </span>
                <span mat-menu-item routerLink="sign-up" class="text-center text-sm">
                  Don't have an account?  <span class="hover:text-gray-600 font-medium cursor-pointer text-primary-700">Sign up</span>
                </span>
            </div>
          </div>
          <ng-template #menuUser>
            <div class="flex flex-col w-64 min-h-72 justify-between">
              <div class="w-full flex flex-col gap-3">
                <div class="flex gap-2 py-1" [routerLink]="userService.menu[0].enlace" matRipple>
                  <mat-icon
                    color="primary"
                  > person </mat-icon>
                  <h3 class="font-bold text-primary-700 font-serif text-lg cursor-pointer">{{ userService.user?.Nombre }}</h3>
                </div>
                <mat-divider class="borderPrimary"></mat-divider>
                <div class="flex flex-col">
                  <ng-container *ngFor="let menu of userService.menu">
                    <ng-container *ngIf="menu.opcion !== 'Home'">
                      <div class="py-2" matRipple>
                        <span [routerLink]="menu.enlace" class="flex gap-2 cursor-pointer group" >
                          <mat-icon class="iconMenu group-hover:text-primary-700"> {{ menu.icon }} </mat-icon>
                        <span class="text-gray-600 font-medium group-hover:text-primary-700">{{ menu.opcion }}</span>
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="w-full flex justify-center items-center gap-2 py-2" matRipple (click)="userService.logout()">
                <mat-icon color="primary"> logout </mat-icon>
                <span class="font-bold text-primary-700 font-serif text-lg cursor-pointer">Sign out</span>
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>

        <button
          mat-icon-button color="primary"
          aria-label="Icon button with a shopping cart icon"
          (click)="opCart.toggle($event)"
          class="btnIcon"
          *ngIf="authService.isLoged | async">
          <mat-icon
            class="text-2xl lg:text-3xl iconHeader"
            [matBadge]="cartService.cart.quantityTotal"
            matBadgeColor="primary"
            matBadgePosition="below after"
            >shopping_cart</mat-icon>
        </button>
        <p-overlayPanel #opCart class="rounded-xl">
          <div class="flex flex-col justify-between w-80 md:w-102 min-h-102 md:min-h-80">
            <div>
              <div class="w-full h-24 flex flex-col items-center gap-3">
                <mat-icon color="primary" class="iconCart text-5xl"> shopping_cart_outlined </mat-icon>
                <h3 class="font-bold text-primary-700 font-serif text-xl">Orders</h3>
              </div>

              <ng-container *ngIf="cartService.cart.products.length > 0 || (cartService.cart.customs && cartService.cart.customs!.length > 0); else cartEmpty">
                <div class="max-h-80 md:max-h-60 overflow-auto scroll-spec pr-3">
                  <ng-container *ngIf="!cartService.cart.isPersonalized; else customMeals">
                    <div *ngFor="let product of cartService.cart.products; let i = index">
                      <mat-divider></mat-divider>
                      <div class="grid grid-cols-1 my-3">
                        <div class="col-span-1 flex justify-between mb-2">
                          <b class="font-bold text-base">{{ i+1 }}. {{ product.name }} ({{ product.quantity }} {{ product.quantity | i18nPlural:mealsMap }})</b>
                          <mat-icon class="cursor-pointer text-primary-700" (click)="cartService.removeCartElement(i, product)">delete</mat-icon>
                        </div>

                        <div class="col-span-1 flex justify-between pl-4 mb-1" *ngFor="let meal of product.meals; let im = index">
                          <span class="text-base">{{ meal.name }} - x{{ meal.quantity }}</span>
                          <span class="text-base">{{ meal.price | currency: 'USD':'symbol':'1.2-2' }}</span>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                    </div>
                  </ng-container>
                  <ng-template #customMeals>
                    <div *ngFor="let product of cartService.cart.customs; let i = index">
                      <mat-divider></mat-divider>
                      <div class="grid grid-cols-1 my-3">
                        <div class="col-span-1 flex justify-between mb-2">
                          <b class="font-bold text-base">Custom #{{ product.id }}. ({{ product.quantity }} {{ product.quantity | i18nPlural:mealsMap }})</b>
                          <mat-icon class="cursor-pointer text-primary-700" (click)="cartService.removeCustomMeal(product)">delete</mat-icon>
                        </div>
                        <div class="col-span-1 pl-4 mb-1 grid grid-cols-8 w-full">
                          <span class="font-bold text-base col-span-4">Ingredient</span>
                          <span class="font-bold text-base col-span-2 text-end">Price unit</span>
                          <span class="font-bold text-base col-span-2 text-end">Price total</span>
                        </div>
                        <div class="col-span-1 justify-between pl-4 mb-1 grid grid-cols-8">
                          <span class="text-base col-span-4">{{ product.protein.name }} ({{ product.protein.description }}) (x{{ product.quantity }})</span>
                          <span class="text-base col-span-2 text-end">{{ product.protein.price | currency: 'USD':'symbol':'1.2-2'}}</span>
                          <span class="text-base col-span-2 text-end">{{ (product.protein.price * product.quantity) | currency: 'USD':'symbol':'1.2-2'}}</span>
                        </div>
                        <div class="col-span-1 justify-between pl-4 mb-1 grid grid-cols-8" *ngIf="product.carb">
                          <span class="text-base col-span-4">{{ product.carb.name }} ({{ product.carb.description }}) (x{{ product.quantity }})</span>
                          <span class="text-base col-span-2 text-end">{{ product.carb.price | currency: 'USD':'symbol':'1.2-2'}}</span>
                          <span class="text-base col-span-2 text-end">{{ (product.carb.price * product.quantity) | currency: 'USD':'symbol':'1.2-2'}}</span>
                        </div>
                        <div class="col-span-1 justify-between pl-4 mb-1 grid grid-cols-8" *ngIf="product.veggies">
                          <span class="text-base col-span-4">{{ product.veggies.name }} (x{{ product.quantity }})</span>
                          <span class="text-base col-span-2 text-end">{{ product.veggies.price | currency: 'USD':'symbol':'1.2-2'}}</span>
                          <span class="text-base col-span-2 text-end">{{ (product.veggies.price * product.quantity) | currency: 'USD':'symbol':'1.2-2'}}</span>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                    </div>
                  </ng-template>
                </div>

                <div class="">
                  <mat-divider></mat-divider>
                  <div class="grid grid-cols-2 my-3">
                    <div class="col-span-1"><span class="font-bold text-base">Total: {{ cartService.cart.quantityTotal }} {{ cartService.cart.quantityTotal | i18nPlural:mealsMap }}</span></div>
                    <div class="col-span-1 flex justify-end"><span class="font-bold text-base">{{ cartService.cart.priceTotal | currency: 'USD':'symbol':'1.2-2'}}</span></div>
                  </div>
                  <mat-divider></mat-divider>
                </div>
              </ng-container>
            </div>
            <ng-template #cartEmpty>
              <div class="flex justify-center items-center md:h-40">
                <span class="text-xl">Your shopping cart is <b>empty</b></span>
              </div>
            </ng-template>
          </div>
        </p-overlayPanel>
      </div>
    </div>
    <div class="order-3 flex justify-end lg:hidden w-3/12 lg:w-auto">
      <mat-icon class="iconBurguerMenu text-3xl" (click)="toggleMobileNav()">menu</mat-icon>
    </div>
  </div>

</header>

<p-sidebar [(visible)]="sidebarMenuVisible" position="right" styleClass="sidenavMenu">
  <div class="flex flex-col gap-10 w-full px-4 justify-between">

    <ng-container *ngIf="(authService.isLoged | async); else profileOff">
      <div class="w-full flex flex-col justify-center items-center gap-2" matRipple>
        <span class="flex justify-center items-center w-16 h-16 bg-primary-700 rounded-full">
          <mat-icon class="iconCart text-5xl text-white">person</mat-icon>
        </span>
        <h3 class="font-normal text-white font-serif text-base">{{ userService.user?.Nombre }}</h3>
        </div>
      </ng-container>

      <ng-template #profileOff>
        <div class="w-full flex flex-col justify-center items-center gap-2">
          <span class="flex justify-center items-center w-16 h-16 bg-gray-500 rounded-full">
            <mat-icon class="iconCart text-5xl text-white">person</mat-icon>
          </span>
          <h3 class="font-normal text-white font-serif text-base flex gap-2">
            <span routerLink="sign-in" matRipple>
              <span class="cursor-pointer hover:text-primary-700">Sign in</span>
            </span>
            |
            <span routerLink="sign-up" matRipple>
              <span class="cursor-pointer hover:text-primary-700">Sign up</span>
            </span>
          </h3>
        </div>
      </ng-template>


    <div class="flex flex-col gap-2">
      <ng-container *ngFor="let i of menuItems">
        <span class="flex gap-4 py-2 cursor-pointer group" matRipple (click)="onLinkClick(i.url)">
          <mat-icon class="text-white group-hover:text-primary-700">{{ i.icon }}</mat-icon>
          <span class="text-white font-medium group-hover:text-primary-700">{{ i.title }}</span>
        </span>
      </ng-container>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="w-full flex justify-center items-center gap-2 py-2" matRipple (click)="userService.logout()">
      <mat-icon color="primary"> logout </mat-icon>
      <span class="font-bold text-primary-700 font-serif text-lg cursor-pointer">Sign out</span>
    </div>
  </ng-template>

</p-sidebar>

