<div class="w-full flex flex-col items-center">

<section id="about" class="about w-10/12 flex justify-center">
    <div class="container" data-aos="fade-up">

      <div class="section-header">
        <h2>Why Choose Us</h2>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gy-4">
        <div class="col-span-1 flex justify-start">
          <img src="../assets/images/about.png" class="h-auto w-144 rounded-2xl mb-4" alt="">
        </div>
        <div class="grid-span-1">
          <div class="content ps-10 flex flex-col gap-5">
            <p class="">
              We believe that achieving your healthy goals should maintain taste and convenience. Here's why we are the best choice for your healthy food needs:
            </p>
            <ul>
              <li><i class="bi bi-check-circle-fill"></i> <strong>Nutritionally Balanced Meals</strong></li>
              <li><i class="bi bi-check-circle-fill"></i> <strong>Tailored to Your Goals</strong></li>
              <li><i class="bi bi-check-circle-fill"></i> <strong>Quality Ingredients</strong></li>
              <li><i class="bi bi-check-circle-fill"></i> <strong>Convenient Delivery</strong></li>
              <li><i class="bi bi-check-circle-fill"></i> <strong>Variety of Delicious Options</strong></li>
            </ul>
            <!-- <div class="position-relative mt-4">
              <img src="assets/img/about-2.jpg" class="img-fluid rounded-4" alt="">
              <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" class="glightbox play-btn"></a>
            </div> -->
          </div>
        </div>
      </div>

    </div>
  </section>
  <section id="stats-counter" class="stats-counter w-10/12 flex justify-cent">
    <div class="container" data-aos="fade-up">

      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-z-4 align-items-center">

        <div class="col-span-1">
          <img src="../assets/images/stats-img.gif" alt="" class="img-fluid">
        </div>

        <div class="col-span-1">

          <div class="stats-item flex items-center">
            <span class="purecounter" data-purecounter-start="0" data-purecounter-end="413" data-purecounter-duration="1">0</span>
            <p><strong>Happy Clients</strong> and disciplined achieving their goals.</p>
          </div><!-- End Stats Item -->

          <div class="stats-item flex items-center">
            <span class="purecounter" data-purecounter-start="0" data-purecounter-end="8245" data-purecounter-duration="1">0</span>
            <p><strong>Orders Prepared</strong> and delivered by delivery or pickup.</p>
          </div><!-- End Stats Item -->

          <div class="stats-item flex items-center">
            <span class="purecounter" data-purecounter-start="0" data-purecounter-end="14" data-purecounter-duration="1">0</span>
            <p><strong>Fridges</strong> to serve you and deliver your meals.</p>
          </div><!-- End Stats Item -->

        </div>

      </div>

    </div>
  </section>

</div>