<section id="services" class="services sections-bg flex justify-center">
    <div class="container" data-aos="fade-up">

      <div class="section-header">
        <h2>Our Plans</h2>
        <p>Choose and enjoy our weekly meals or customize to your liking with available ingredients.</p>
      </div>
      <div class="">
      <div class=" flex flex-col items-center md:flex-row justify-center gap-5" data-aos="fade-up" data-aos-delay="100">

        <div class="w-4/5 md:w-1/4">
          <div class="service-item  position-relative">
            <div class="icon">
              <span class="material-icons-outlined">
                set_meal
                </span>
            </div>
            <h3>Our Meals</h3>
            <p>Choose from our wide variety of rotating meals every week.</p>
            <a routerLink="/order/meals" class="readmore stretched-link">Order now <i class="bi bi-arrow-right"></i></a>
          </div>
        </div><!-- End Service Item -->

        <div class="w-4/5 md:w-1/4">
          <div class="service-item position-relative">
            <div class="icon">
              <span class="material-symbols-outlined">
                grocery
                </span>
            </div>
            <h3>Personalized Meals</h3>
            <p>Choose from all the available ingredients and create your own meals.</p>
            <a routerLink="/order/meals-personalized" class="readmore stretched-link">Order now <i class="bi bi-arrow-right"></i></a>
          </div>
        </div><!-- End Service Item -->


      </div>
    </div>
    </div>
  </section>
