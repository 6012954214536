<footer id="footer" class="footer flex flex-col items-center">

    <div class="container w-full md:w-10/12">
      <div class="grid grid-cols-1 md:grid-cols-5 px-6 gap-3 md:gap-0">
        <div class="col-span-1 md:col-span-2 footer-info flex flex-col items-center md:items-start gap-3">
          <a href="index.html" class="w-32 flex items-center">
            <img src="../assets/images/logo.png">
          </a>
          <p>Maintain discipline and achieve your goals with Mg Meals.</p>
          <div class="social-links flex">
            <!-- <a href="#" class="twitter"><i class="bi bi-twitter-x"></i></a>
            <a href="#" class="facebook"><i class="bi bi-facebook"></i></a> -->
            <a href="https://www.instagram.com/mg_meals/" class="instagram"><i class="bi bi-instagram"></i></a>
            <a href="https://wa.me/+14072321846" class="linkedin"><i class="bi bi-whatsapp"></i></a>
          </div>
        </div>
  
        <div class="col-span-1 footer-links flex flex-col items-center md:items-start">
          <h4>Useful Links</h4>
          <ul>
            <li><a href="#hero">Home</a></li>
            <li><a href="#about">Why Choose</a></li>
            <li><a href="#services">Plans</a></li>
            <!-- <li><a href="#">Terms of service</a></li>
            <li><a href="#">Privacy policy</a></li> -->
          </ul>
        </div>
  
        <div class="col-span-1 footer-links flex flex-col items-center md:items-start">
          <h4>Our Plans</h4>
          <ul>
            <li><a href="#">Our Meals</a></li>
            <li><a href="#">Personalized Meals</a></li>
          </ul>
        </div>
  
        <div class="col-span-1  footer-contact text-center text-md-start items-center md:items-start">
          <h4 class="text-center md:text-start">Contact Us</h4>
          <p class=" text-center md:text-start">
            3601 Vineland Rd Suite 1 <br>
            Orlando, FL 32811<br>
            United States <br><br>
            <strong>Phone:</strong> +1 407-232-1846<br>
            <strong>Email:</strong> info&#64;mgmeals.com<br>
          </p>
  
        </div>
  
      </div>
    </div>
  
    <div class="container mt-4">
      <div class="copyright">
        &copy; 2024 Copyright <strong><span>Mg Meals</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        Developed by <a href="#">ZyCoffee Solutions</a>
      </div>
    </div>
  
  </footer>