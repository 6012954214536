import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FitMealsService } from './fit-meals.service';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { DishesShow } from '../interfaces/dishes.interface';
import { DishesPersonalized, RequestDishesPersonalized } from '../interfaces/personalized.interface';
import { DeliveryMethods } from '../interfaces/delivery_methods.interface';
import { ObjetivoPlato, OrderRequest, OrderVerification, Plato } from '../interfaces/order.interface';
import { Cliente } from '../interfaces/cliente.interface';
import { Objetive } from '../../client/interfaces/client.interface';
import { MealsCartService } from './meals-cart.service';
import { Cart, CartElement, CartMeal } from '../interfaces/cart.interface';
import { UserService } from '../../admin-panel/services/user/user.service';
import { PaymentInfo } from '../interfaces/api/reportePedido-response.interface';

@Injectable({
  providedIn: 'root',
})
export class FitMealsDataService {
  private _dishes = new BehaviorSubject<any>(null);
  public dishes = this._dishes.asObservable();

  private _fridges = new BehaviorSubject<any>(null);
  public fridges = this._fridges.asObservable();

  private _ingredients = new BehaviorSubject<any>(null);
  public ingredients = this._ingredients.asObservable();

  private _locations = new BehaviorSubject<any>(null);
  public locations = this._locations.asObservable();

  // public orderDishes?: DishesShow[];
  public dishesPersonalized: DishesPersonalized[] = [];
  public orderDishesPersonalized?: DishesPersonalized[];
  public deliveryMethods?: DeliveryMethods;
  public clientInfo?: Cliente;
  public order!: OrderRequest;

  public isPersonalized: boolean = false;
  public orderCreated = new Subject<number>();
  public orderNumber?: number;

  public orderInfo!: OrderVerification;
  public paymentInfo!: PaymentInfo;

  public cart?: Cart;

  public goals: Objetive[] = [
    { id: 1, name: 'Weight Loss', description: 'Pérdida de peso' },
    { id: 2, name: 'Muscle Gain', description: 'Aumento de masa muscular' },
    { id: 3, name: 'Maintenance', description: 'Mantenimiento' },
  ];

  public goal: Objetive = {} as Objetive;

  constructor(
    protected fitMealsService: FitMealsService,
    protected toastr: ToastrService
  ) {
  }

  getDishes() {
    this.fitMealsService.getMeals(this.goal.id).subscribe({
      next: (data) => {
        if (data.status == 1) {
          this._dishes.next(data.response);
        } else {
          if (data.status == 2) {
            this.toastr.warning('Oh no! No meals for the goal selected');
            this._dishes.next([]);
          } else {
            this.toastr.error(
              'Oh no! An error occurred while obtaining the meals'
            );
          }
        }
      },
      error: (error) => {
        this.toastr.error('Oh no! An error occurred while obtaining the meals');
      },
    });
  }

  getFridge() {
    this.fitMealsService.getFridge().subscribe({
      next: (data) => {
        if (data.status == 1) {
          this._fridges.next(data.response);
        } else {
          this.toastr.error(
            'Oh no! An error occurred while obtaining the refrigerators'
          );
        }
      },
      error: (error) => {
        this.toastr.error(
          'Oh no! An error occurred while obtaining the refrigerators'
        );
      },
    });
  }

  getFridgeNear(coordinates: string) {
    this.fitMealsService.getFridgesNear(coordinates).subscribe({
      next: (data) => {
        if (data.status == 1) {
          this._fridges.next(data.response);
        } else {
          this._fridges.next([]);
        }
      },
      error: (error) => {
        this.toastr.error(
          'Oh no! An error occurred while obtaining the refrigerators'
        );
      },
    });
  }

  getIngredients() {
    this.fitMealsService.getIngredients().subscribe({
      next: (data) => {
        if (data.status == 1) {
          this._ingredients.next(data.response);
        } else {
          this.toastr.error(
            'Oh no! An error occurred while obtaining the ingredients'
          );
        }
      },
      error: (error) => {
        this.toastr.error(
          'Oh no! An error occurred while obtaining the ingredients'
        );
      },
    });
  }

  getLocations(address: string) {
    this.fitMealsService.autocompleteAddress(address).subscribe({
      next: (data) => {
        if (data.status != 1) return this._locations.next([]);

        this._locations.next(data.response);
      },
      error: (error) => {
        this.toastr.error(
          'Oh no! An error occurred while obtaining the locations'
        );
      },
    });
  }

  getLocationsPickup(address: string) {
    this.fitMealsService.autocompleteAllAddress(address).subscribe({
      next: (data) => {
        if (data.status != 1) return this._locations.next([]);

        this._locations.next(data.response);
      },
      error: (error) => {
        this.toastr.error(
          'Oh no! An error occurred while obtaining the locations'
        );
      },
    });
  }

  prepareOrder(): OrderRequest {
    let plan: number = 1;

    let ingredient = {
      idIngredienteDetalle: 0,
    };

    let ingredients: any[];

    let orderDishesPersonalizedRequest: RequestDishesPersonalized[] = [];

    this.orderDishesPersonalized?.forEach((obj: DishesPersonalized) => {
      ingredients = [];

      let personalized: RequestDishesPersonalized;

      ingredient = {
        idIngredienteDetalle: obj.protein.idDetail ?? 0,
      };
      ingredients.push(ingredient);

      if (obj.carb) {
        ingredient = {
          idIngredienteDetalle: obj.carb.idDetail ?? 0,
        };
        ingredients.push(ingredient);
      }

      // obj.veggies.forEach((veggie: IngredientSelected) => {
      if (obj.veggies != undefined) {
        ingredient = {
          idIngredienteDetalle: obj.veggies?.idDetail ?? 0,
        };
        ingredients.push(ingredient);
      }
      // })

      personalized = {
        id: obj.id,
        quantity: obj.quantity,
        priceDish: obj.priceDish,
        comments: this.clientInfo?.comments ?? '',
        ingredients: ingredients,
      };

      orderDishesPersonalizedRequest.push(personalized);
    });

    this.isPersonalized = false;
    if (this.orderDishesPersonalized != undefined) {
      this.isPersonalized = true;
      plan = 2;
    } else {
      this.isPersonalized = false;
    }

    return (this.order = {
      idCliente: this.clientInfo?.idCliente ?? 0,
      idPlan: plan,
      personalizado: this.isPersonalized,
      deliveryPickUp: true ? this.deliveryMethods?.idFridge == null : false,
      entrega: {
        fecha: this.deliveryMethods?.date ?? '',
        hora: this.deliveryMethods?.time ?? '',
        idNevera: this.deliveryMethods?.idFridge ?? null,
        direccion: this.deliveryMethods?.address ?? '',
        coordenadas: this.deliveryMethods?.coordinates ?? '',
      },
      objetivoPlatos: (this.cart!.products?.map((obj: CartElement) => ({
        idObjetivo: obj.goal?.id == 0 ? null : obj.goal?.id,
        platos: obj.meals.map((obj2: CartMeal) => ({
          id_plato: obj2.id ?? 0,
          cantidad: obj2.quantity,
          comentario: '',
        })),
      })) || []) as ObjetivoPlato[],
      platosPersonalizados: orderDishesPersonalizedRequest?.map(
        (obj: RequestDishesPersonalized) => ({
          ingredientes: obj.ingredients,
          Comentario: obj.comments,
          cantidad: obj.quantity,
        })
      ),
      idPayment: '',
      idOrder: 0,
    });
  }

  createOrder(): void {
    this.fitMealsService.createOrder(this.order).subscribe({
      next: (data) => {
        if (data.status == 1) {
          this.toastr.success('Great! You order has been procesed!');
          this.orderNumber = data.response.numeroPedido;
          this.orderCreated.next(this.orderNumber);
          localStorage.removeItem('mealsOrder');
        } else {
          this.toastr.error('Oh no! Your order has not been processed');
          throw new Error('Error al crear el pedido');
        }
      },
      error: (err) => {
        this.toastr.error('Oh no! Your order has not been processed');
        throw new Error('Error al crear el pedido');
      },
    });
  }

  verifyOrder(order: OrderVerification): Observable<PaymentInfo> {
    return this.fitMealsService.getPaymentInfo(order).pipe(
      map((data) => {
        if (data.status == 1) {
          this.orderInfo = order;
          this.paymentInfo = data.response;
          this.order.idOrder = data.response.paymentId;
          this.orderInfo.idOrderPrev = data.response.paymentId;
          return data.response;
        } else {
          this.toastr.error('Oh no! Your order has not been processed');
          throw new Error('Error al crear el pedido');
        }
      })
      // catchError(err => {
      //   this.toastr.error('Oh no! Your order has not been processed');
      //   throw new Error("Error al crear el pedido "+err);
      // })
    );
  }

  sendReferralCode(order: OrderVerification): Observable<PaymentInfo> {
    return this.fitMealsService.getPaymentInfo(order).pipe(
      map((data) => {
        if (data.status == 1) {
          this.orderInfo = order;
          this.paymentInfo = data.response;
          return data.response;
        } else {
          this.toastr.error('Oh no! Your order has not been processed');
          throw new Error('Error al crear el pedido');
        }
      })
    );
  }

  clearService(): void {
    this.clientInfo = undefined;
    this.deliveryMethods = undefined;
    // this.orderDishes = undefined;
    this.cart = undefined;
    this.orderDishesPersonalized = undefined;
    localStorage.removeItem('deliveryMethods');
    localStorage.removeItem('personalizedOrder');
    localStorage.removeItem('cart');
    localStorage.removeItem('goal');
    localStorage.removeItem('clientInfo');
  }
}
