<section id="faq" class=" faq w-full px-2 md:px-0 md:w-10/12">
    <div class="container" data-aos="fade-up">

      <div class="grid grid-cols-1 md:grid-cols-3 md:gap-x-8">

        <div class="col-span-1">
          <div class="content px-xl-5">
            <h3>Frequently Asked <strong>Questions</strong></h3>
            <p>
              Do you have any doubts? I hope you can clarify them with our frequently asked questions
            </p>
          </div>
        </div>

        <div class="col-span-1 md:col-span-2  px-1">


          <mat-accordion>
            <mat-expansion-panel >
              <mat-expansion-panel-header class="panel-title" >
                <mat-panel-title class="font-bold text-sm md:text-lg py-4 md:py-0 gap-2"><span class="text-primary-700">1. </span>
                   <span>How can I personalize meals?</span> </mat-panel-title>
              </mat-expansion-panel-header>
              <p class="text-sm text-justify">You can enter our personalized plan, and select the protein, then the desired amount (You can see its price in parentheses "()"), then select the carbohydrate, again the desired amount, some vegetable (only if you want it), you leave us a comment (just in case you are allergic to something or don't want something), select how many dishes you want and add them.
                Make sure you see it on the table and add as many dishes as you want.</p>
            </mat-expansion-panel>

            <mat-expansion-panel >
              <mat-expansion-panel-header class="panel-title" >
                <mat-panel-title class="font-bold text-sm md:text-lg py-4 md:py-0 gap-2">
                  <span class="text-primary-700">2.</span>
                  Why do I only have availability on Mondays for delivery? </mat-panel-title>
              </mat-expansion-panel-header>
              <p class="text-sm text-justify"> Because currently our delivery drivers only deliver on those scheduled day.</p>
            </mat-expansion-panel>

            <mat-expansion-panel >
              <mat-expansion-panel-header class="panel-title" >
                <mat-panel-title class="font-bold text-sm md:text-lg py-4 md:py-0 gap-2">
                  <span class="text-primary-700">3.</span>
                  How can I indicate that I want delivery to my current location? </mat-panel-title>
              </mat-expansion-panel-header>
              <p class="text-sm text-justify">You must make sure you have a device with geolocation and allow access to the location to know where you are located and deliver your order.</p>
            </mat-expansion-panel>

            <mat-expansion-panel >
              <mat-expansion-panel-header class="panel-title" >
                <mat-panel-title class="font-bold text-sm md:text-lg py-4 md:py-0 gap-2">
                  <span class="text-primary-700">4.</span>
                  Why is the delivery option not available in some cases? </mat-panel-title>
              </mat-expansion-panel-header>
              <p class="text-sm text-justify">To have the delivery option, you must order more than 10 meals.</p>
            </mat-expansion-panel>

            <mat-expansion-panel >
              <mat-expansion-panel-header class="panel-title" >
                <mat-panel-title class="font-bold text-sm md:text-lg py-4 md:py-0 gap-2">
                  <span class="text-primary-700">5.</span>
                  Why does it tell me that there is no delivery in my area? </mat-panel-title>
              </mat-expansion-panel-header>
              <p class="text-sm text-justify">Because currently we only deliver to an established area. We are working to deliver to more areas.</p>
            </mat-expansion-panel>

            <mat-expansion-panel >
              <mat-expansion-panel-header class="panel-title" >
                <mat-panel-title class="font-bold text-sm md:text-lg py-4 md:py-0 gap-2">
                  <span class="text-primary-700">6.</span>
                  Is it safe to make the payment? </mat-panel-title>
              </mat-expansion-panel-header>
              <p class="text-sm text-justify">Yes, we do not store any information about your payment method</p>
            </mat-expansion-panel>


            <mat-expansion-panel >
              <mat-expansion-panel-header class="panel-title" >
                <mat-panel-title class="font-bold text-sm md:text-lg py-4 md:py-0 gap-2">
                  <span class="text-primary-700">7.</span>
                  My payment was processed successfully but the order was not completed, what can I do?</mat-panel-title>
              </mat-expansion-panel-header>
              <p class="text-sm text-justify">If your payment was successfully processed and you have a charge related to us, contact us or come to help us with your order.</p>
            </mat-expansion-panel>

          </mat-accordion>

        </div>
      </div>

    </div>
  </section>
