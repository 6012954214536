import { CommonModule ,} from '@angular/common';
import { ChangeDetectionStrategy, Component, signal} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'asked',
  templateUrl: './asked.component.html',
  styleUrl: './asked.component.css',
})
export class AskedComponent { 
  readonly panelOpenState = signal(false);
}
