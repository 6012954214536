import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';

declare var PureCounter: any;
@Component({
  selector: 'about-page',
  templateUrl:'./about.component.html',
  styleUrl: './about.component.css',
})
export class AboutComponent implements AfterViewInit{
  ngAfterViewInit():void {
    new PureCounter();
  }
 }
