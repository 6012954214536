import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.css']
})
export class BackComponent {

  constructor(
    private router: Router,
    private _location: Location
  )
  {}

  goBack():void{
    this._location.back();
  }
}
