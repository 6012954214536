import { Injectable, inject } from '@angular/core';
import { AuthService } from '../modules/auth/services/auth.service';
import { TokenStorageService } from '../modules/auth/services/token-storage.service';
import { Router } from '@angular/router';
import { data_user_client, menu } from '../modules/auth/interface/interfaces';
import { ClientService } from '../modules/client/services/client.service';
import { BehaviorSubject, map, share, throwError } from 'rxjs';
import { Client } from '../modules/client/interfaces/client.interface';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private authService = inject(AuthService);
  private tokenService = inject(TokenStorageService);
  public clientService = inject(ClientService);

  public user?: data_user_client;
  public menu: menu[] = [];
  public client: Client = {} as Client;
  // public _session!: LoginClient;

  private _client = new BehaviorSubject<Client>(this.client);
  public clientObservable = this._client.asObservable().pipe(share());

  constructor(private router: Router, private toastr: ToastrService) {
    if (!this.user) {
      this.getUserSession();
    }
    // if (this.client && Object.keys(this.client).length == 0) {
    //   this.getUserClient();
    //   this._client.subscribe()
    // }
  }

  getUserSession() {
    const session = this.tokenService.verifySession();
    if (session) {
      this.user = session.data_user;
      this.menu = session.menu;
    }

    this.authService.isLoged.subscribe({
      next: (value) => {
        if (value) {
          //TODO SI ESTA LOGUEADO QUE HAGO
        } else {
          // this.router.navigate(['/']);
        }
      },
    });
  }

  getUserClient(): void {
    if (this.user) {
      this.clientService
        .getInfo(this.user.idClient)
        .pipe(
          map((data): Client | null => {
            if (data.status != 1) {
              this.toastr.error(data.response.toString(), data.detalle);
              return null;
            } else {
              return (this.client = {
                name: data.response.nombre,
                lastname: data.response.apellido,
                phone: data.response.telefono,
                email: data.response.email,
                dateOfBirth: data.response.fechaNacimiento,
                years: data.response.years,
                clientCode: data.response.codigoCliente,
                genre: {
                  id: data.response.genero?.idGenero ?? 0,
                  name: data.response.genero?.descripcion,
                },
                height: data.response?.altura,
                weight: data.response?.peso ?? 0,
                objetive: {
                  id: data.response.objetivo?.idObjetivo,
                  name: data.response.objetivo?.nombre ?? 'Not goal',
                  description: data.response.objetivo?.descripcion ?? '',
                },
                activity: {
                  id: data.response.nivel_actividad?.idActividad,
                  name: data.response.nivel_actividad?.nombre ?? 'None',
                  description: data.response.nivel_actividad?.descripcion,
                },
                membership: data.response.membresia
              });
            }
          })
        )
        .subscribe({
          next: (data) => {
            if (data) {
              this._client.next(data);
            }
          },
          error: (err) => {
            this.toastr.error('Unexpected error!');
            throwError('Error:', err);
          },
        });
    }
  }

  logout() {
    this.tokenService.signOutClient();

    this.user = undefined;
    this.client = {} as Client;
    localStorage.removeItem('cart');
    localStorage.removeItem('goal');

    this.authService.isLoged.subscribe({
      next: (value) => {
        this.router.navigate(['/']);
      },
    });
  }
}


