<p-overlayPanel #op>
  <div class="flex flex-column gap-3 w-25rem">
      <div>
          <span class="font-medium text-900 block mb-2">Share this document</span>

      </div>
      <div>
          <span class="font-medium text-900 block mb-2">Invite Member</span>

      </div>
      <div>
          <span class="font-medium text-900 block mb-2">Team Members</span>
          <ul class="list-none p-0 m-0 flex flex-column gap-3">
              <!-- <li *ngFor="let member of members" class="flex align-items-center gap-2">
                  <img [src]="'https://primefaces.org/cdn/primeng/images/demo/avatar/' + member.image" style="width: 32px" />
                  <div>
                      <span class="font-medium">{{ member.name }}</span>
                      <div class="text-sm text-color-secondary">{{ member.email }}</div>
                  </div>
                  <div class="flex align-items-center gap-2 text-color-secondary ml-auto text-sm">
                      <span>{{ member.role }}</span>
                      <i class="pi pi-angle-down"></i>
                  </div>
              </li> -->
          </ul>
      </div>
  </div>
</p-overlayPanel>
