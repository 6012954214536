 <!-- ======= Header ======= -->
 <section id="topbar" class="topbar flex items-center justify-center">
  <div class="container flex justify-center md:justify-between w-full md:w-10/12">
    <div class="contact-info flex items-center">
      <i class="bi bi-envelope flex items-center"><a href="mailto:info@mgmeals.com">info&#64;mgmeals.com</a></i>
      <i class="bi bi-phone flex items-center ms-4"><a href="tel:+14072321846">+1 407-232-1846</a></i>
    </div>
    <div class="social-links hidden md:flex items-center">
      <a href="https://www.instagram.com/mg_meals/" class="instagram"><i class="bi bi-instagram"></i></a>
      <a href="https://wa.me/+14072321846" class="linkedin"><i class="bi bi-whatsapp"></i></a>
    </div>
  </div>
</section><!-- End Top Bar -->
