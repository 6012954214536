import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environments';
import { ClientRequest, ClientResponse } from '../interfaces/client-api.interface';
import { Activity, Client, Genre, Objetive } from '../interfaces/client.interface';
import { ChangePasswordObj } from '../interfaces/change-password.interface';
import { GenreResponse, ResponseGenre } from '../interfaces/genres.interface';
import { ActivityResponse, ResponseActivity } from '../interfaces/activity-api.interface';
import { ObjetiveResponse, ResponseObjetive } from '../interfaces/objetive-api.interface';
import { ResponsePost } from 'src/app/helpers/interfaces/response-api.interface';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private baseUrl: string = environment.baseUrl;

  public client?: Client;

  constructor(private http: HttpClient) {}

  getInfo(idClient: number): Observable<ClientResponse> {
    const url = `${this.baseUrl}/operacionesClient/GetClientById`;

    const params = new HttpParams().set('idCliente', idClient);

    return this.http
      .get<ClientResponse>(url, { params })
      .pipe(
        catchError(() =>
          throwError(() => 'Ha ocurrido un error, intente nuevamente')
        )
      );
  }

  getActivity(): Observable<Activity[]> {
    const url = `${this.baseUrl}/operacionesClient/GetActivity`;

    return this.http.get<ActivityResponse>(url).pipe(
      map((data: ActivityResponse): Activity[] => {
        if (data.status != 1) throw new Error(data.detalle);
        const activitys: Activity[] = [];

        data.response.forEach((activity: ResponseActivity) => {
          activitys.push({
            id: activity.idActividad,
            name: activity.nombre,
            description: activity.descripcion,
          });
        });
        return activitys;
      }),
      catchError(() => throwError(() => 'Something went wrong, try again!'))
    );
  }

  getGoals(): Observable<Objetive[]> {
    const url = `${this.baseUrl}/Operaciones/GetObjetives`;

    return this.http.get<ObjetiveResponse>(url).pipe(
      map((data: ObjetiveResponse): Objetive[] => {
        if (data.status != 1) throw new Error(data.detalle);
        const objetives: Objetive[] = [];

        data.response.forEach((objetive: ResponseObjetive) => {
          objetives.push({
            id: objetive.idObjetivo,
            name: objetive.nombre,
            description: objetive.descripcion ?? '',
          });
        });
        return objetives;
      }),
      catchError(() => throwError(() => 'Something went wrong, try again!'))
    );
  }

  getGenres(): Observable<Genre[]> {
    const url = `${this.baseUrl}/Operaciones/GetGenero`;

    return this.http.get<GenreResponse>(url).pipe(
      map((data: GenreResponse): Genre[] => {
        if (data.status != 1) throw new Error(data.detalle);
        const genres: Genre[] = [];

        data.response.forEach((genreResponse: ResponseGenre) => {
          genres.push({
            id: genreResponse.idGenero,
            name: genreResponse.descripcion,
            description: genreResponse.descripcion,
          });
        });
        return genres;
      }),
      catchError(() => throwError(() => 'Something went wrong, try again!'))
    );
  }

  changePassword(obj: ChangePasswordObj): Observable<any> {
    const url = `${this.baseUrl}/AuthClient/ChangePasswordVerify`;

    const body = {
      idCliente: obj.idCliente,
      passwordOld: obj.passwordOld,
      passwordNew: obj.passwordNew,
    };

    return this.http.put<any>(url, body).pipe(
      catchError((error) => {
        console.error('Ha ocurrido un error:', error);
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }

  updatePersonalInfo(body: ClientRequest): Observable<ResponsePost> {
    const url = `${this.baseUrl}/operacionesClient/ModifyClientInformation`;

    return this.http.put<ResponsePost>(url, body).pipe(
      catchError((error) => {
        console.error(error);
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }
}
