import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackComponent } from '../components/back/back.component';
import { MaterialModule } from '../helpers/material/material.module';
import { HeaderComponent } from '../components/header/header.component';
import { TopBarComponent } from '../components/top-bar/top-bar.component';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { FooterComponent } from '../components/footer/footer.component';
import { AboutComponent } from '../components/sections/about/about.component';
import { PlansComponent } from '../components/sections/plans/plans.component';
import { CallToActionOrderComponent } from '../components/sections/call-to-action-order/call-to-action-order.component';
import { AskedComponent } from '../components/sections/asked/asked.component';
import { ContactComponent } from '../components/sections/contact/contact.component';
import { ScrollToTopComponent } from '../components/scroll-to-top/scroll-to-top.component';
import { PrimeNGModule } from '../helpers/prime-ng/prime-ng.module';
import { PanelUserOverlayComponent } from '../components/panel-user-overlay/panel-user-overlay.component';


@NgModule({
  declarations: [
    BackComponent,
    HeaderComponent,
    AboutComponent,
    CallToActionOrderComponent,
    ScrollToTopComponent,
    ContactComponent,
    AskedComponent,
    PlansComponent,
    FooterComponent,
    TopBarComponent,
    PanelUserOverlayComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PrimeNGModule,
  ],
  exports: [
    BackComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    CallToActionOrderComponent,
    AskedComponent,
    PlansComponent,
    TopBarComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    ScrollToTopComponent,
    PanelUserOverlayComponent,
    MaterialModule,
    PrimeNGModule
  ],
  providers: [provideNgxMask()],
})
export class SharedModule {}
