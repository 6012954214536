import { Component } from '@angular/core';

@Component({
  selector: 'panel-user-overlay',
  templateUrl: './panel-user-overlay.component.html',
  styleUrl: './panel-user-overlay.component.css'
})
export class PanelUserOverlayComponent {

}
