import { ChangeDetectorRef, Component, EventEmitter, HostListener, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { TokenStorageService } from 'src/app/modules/auth/services/token-storage.service';
import { MealsCartService } from 'src/app/modules/fit-meals-v2/services/meals-cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'header-page',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent {
  public authService = inject(AuthService);
  public userService = inject(UserService);
  public cartService = inject(MealsCartService);

  @Output() linkClicked = new EventEmitter<string>();

  public isLogged: boolean = false;
  sidebarMenuVisible = false;
  public isMobile?: boolean;
  public menuItems = [
    {
      icon: 'home',
      title: 'Home',
      url: 'hero',
    },
    {
      icon: 'groups',
      title: 'Why choose us?',
      url: 'about',
    },
    {
      icon: 'menu_book',
      title: 'Plans',
      url: 'plans',
    },
    {
      icon: 'badge',
      title: 'Membership',
      url: 'membership',
    },
    {
      icon: 'contact_page',
      title: 'Contact',
      url: 'contact',
    },
  ];

  public mealsMap = {
    '=1': 'meal',
    '=2': 'meals',
    other: 'meals',
  };

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.userService.getUserSession();

    this.authService.isLoged.subscribe({
      next: (value) => {
        console.warn(value);

        if (value) return (this.isLogged = true);
        this.cdr.detectChanges();
        return (this.isLogged = false);
      },
    });

    this.isMobile = window.innerWidth < 650;
  }

  onLinkClick(route: string): void {
    this.linkClicked.emit(route);
  }

  toggleMobileNav() {
    this.sidebarMenuVisible = !this.sidebarMenuVisible;
  }

  isFixed = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    const scrollY = window.scrollY || window.pageYOffset;
    this.isFixed = scrollY > 50; // Cambia este valor según tus necesidades
  }
}
