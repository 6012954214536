import { ChangeDetectorRef, Injectable, inject } from '@angular/core';
import { DishesShow } from '../interfaces/dishes.interface';
import { ToastrService } from 'ngx-toastr';
import { CartElement, CartMeal, Cart } from '../interfaces/cart.interface';
import { FitMealsDataService } from './fit-meals-data.service';
import { DishesPersonalized } from '../interfaces/personalized.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MealsCartService {
  private dataFitMealsService = inject(FitMealsDataService);
  public dishesAdd: DishesShow[] = [];
  public cart: Cart;
  public elementCart: CartElement = {
    meals: [],
    price: 0,
    quantity: 0,
    goal: this.dataFitMealsService.goal,
    name: '',
    active: false,
  };

  public priceTotal: number = 0;
  public mealsTotal: number = 0;
  private cartSubject = new BehaviorSubject<Cart>({} as Cart);
  public cartUpdated = this.cartSubject.asObservable();

  constructor(private toastr: ToastrService) {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      this.cart = JSON.parse(savedCart);
      if (this.cart.isPersonalized) {
        this.dataFitMealsService.orderDishesPersonalized = this.cart.customs;
      }
    } else {
      // Si no hay datos guardados, inicializar un carrito vacío
      this.cart = {
        products: [],
        quantityTotal: 0,
        priceTotal: 0,
      };
    }
  }

  addMeal(meal: DishesShow): void {
    //* Busco si esta agregado
    if (this.cart.products.length == 0) return this.createElementCart(meal);

    const productActive = this.cart.products.find(
      (product) => product.active === true
    );

    if (productActive) {
      let dish = productActive.meals?.find((obj) => obj.id === meal.idPlato);
      if (dish) {
        dish.quantity = dish.quantity + 1;
        dish.price = dish.price + meal.priceUnit;
        productActive.price = productActive.price + dish.price;
        productActive.quantity = productActive.quantity + 1;
        this.cart.quantityTotal = this.cart.quantityTotal + 1;
        this.cart.priceTotal = this.cart.priceTotal + meal.priceUnit;
      } else {
        this.addNewElementToCart(meal, productActive);
      }
    }

    if (this.cart.quantityTotal === 6) {
      this.toastr.info('You need 4 more meals to have the delivery option.');
    } else if (this.cart.quantityTotal === 7) {
      this.toastr.info('You need 3 more meals to have the delivery option.');
    } else if (this.cart.quantityTotal === 8) {
      this.toastr.info('You need 2 more meals to have the delivery option.');
    } else if (this.cart.quantityTotal === 9) {
      this.toastr.info('Choose another meal. You only need 1 more!');
    } else if (this.cart.quantityTotal == 10) {
      this.toastr.info('You have a delivery option! 🚚');
    }

    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  removeMeal(meal: DishesShow): void {
    if (this.cart.products.length == 0) return;

    const productActive = this.cart.products.find(
      (product) => product.active === true
    );

    //* Busco el plato para actualizarlo

    if (productActive) {
      let dish = productActive.meals?.find((obj) => obj.id === meal.idPlato);
      if (dish) {
        dish.quantity = dish.quantity - 1;
        dish.price = dish.price - meal.priceUnit;
        productActive.price = productActive.price - dish.price;
        productActive.quantity = productActive.quantity - 1;
        this.cart.quantityTotal = this.cart.quantityTotal - 1;
        this.cart.priceTotal = this.cart.priceTotal - meal.priceUnit;

        //* Si llega a 0, elimino el plato del array
        if (dish.quantity == 0)
          productActive.meals = productActive.meals.filter(
            (obj) => obj.id !== meal.idPlato
          );
      }
      if (productActive.quantity == 0)
        this.cart.products = this.cart.products.filter(
          (product) => product.active === false
        );
    }

    if (this.cart.quantityTotal === 9) {
      this.toastr.info('Remember to choose one more meal to have delivery');
    }

    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  createElementCart(meal: DishesShow) {
    const newMeal: CartMeal = {
      id: meal.idPlato,
      name: meal.name,
      price: meal.priceUnit,
      quantity: 1,
      image: meal.imagePath,
    };
    this.elementCart.active = true;
    this.elementCart.price = newMeal.price;
    this.elementCart.quantity = 1;
    this.elementCart.name = this.dataFitMealsService.goal.name;
    this.elementCart.goal = this.dataFitMealsService.goal;
    this.cart.isPersonalized = false;
    this.cart.idPlan = 1;
    if (this.elementCart.meals) {
      this.elementCart.meals.push({ ...newMeal });
    } else {
      this.elementCart.meals = [newMeal];
    }
    this.cart.products.push(this.elementCart);
    this.elementCart = {} as CartElement;
    this.cart.priceTotal = this.cart.priceTotal + meal.priceUnit;
    this.cart.quantityTotal = this.cart.quantityTotal + 1;
  }

  addNewElementToCart(meal: DishesShow, elementCart: CartElement) {
    const newMeal: CartMeal = {
      id: meal.idPlato,
      name: meal.name,
      price: meal.priceUnit,
      quantity: 1,
      image: meal.imagePath,
    };
    elementCart.price = elementCart.price + meal.priceUnit;
    elementCart.quantity = elementCart.quantity + 1;
    elementCart.meals.push({ ...newMeal });
    this.cart.quantityTotal = this.cart.quantityTotal + 1;
    this.cart.priceTotal = this.cart.priceTotal + meal.priceUnit;
  }

  removeCartElement(index: number, product: CartElement) {
    this.cart.products.splice(index, 1);
    this.cart.quantityTotal = this.cart.quantityTotal - product.quantity;
    this.cart.priceTotal = this.cart.priceTotal - product.price;
    this.cartSubject.next(this.cart);
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  getMealById(id: number) {
    if (this.cart.products.length == 0) return;

    const productActive = this.cart.products.find(
      (product) => product.active === true
    );

    if (productActive)
      return productActive.meals?.find((meal) => meal.id === id);
    return;
  }

  addCustomMeal(customMeal: DishesPersonalized[]) {
    this.cart.isPersonalized = true;
    this.cart.idPlan = 2;
    this.cart.customs = customMeal;
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  removeCustomMeal(meal: DishesPersonalized) {
    if (!this.cart.customs) return

    let index = this.cart.customs.findIndex(
      (obj) => obj.id === meal.id
    );

    let indexPerson = this.dataFitMealsService.dishesPersonalized.findIndex(
      (obj) => obj.id === meal.id
    );

    if (index > -1) {
      this.cart.customs.splice(index, 1);
      this.dataFitMealsService.dishesPersonalized.splice(indexPerson, 1)
    }
    this.cart.quantityTotal = this.cart.quantityTotal - meal.quantity;
    this.cart.priceTotal = this.cart.priceTotal - meal.priceDish * meal.quantity;
    this.cartSubject.next(this.cart);
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  getProductActive() {
    return this.cart.products.find((product) => product.active === true);
  }
}
