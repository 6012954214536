import { NgModule } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputOtpModule } from 'primeng/inputotp';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { TabMenuModule } from 'primeng/tabmenu';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  exports: [
    PanelModule,
    CardModule,
    ButtonModule,
    InputOtpModule,
    BreadcrumbModule,
    OverlayPanelModule,
    SidebarModule,
    SkeletonModule,
    TabMenuModule,
    MessagesModule,
  ],
})
export class PrimeNGModule {}
